function trackSpinWheel() {
    PCHGA.trackEvent('surveytab', 'impression', 'survey_rewards_wheel');
}

function trackSpinClick() {
    PCHGA.trackEvent('surveytab', 'spinstart', 'survey_rewards_wheel');
}

function trackSpinComplete() {
    PCHGA.trackEvent('surveytab', 'spincomplete', 'survey_rewards_wheel');
}

export { trackSpinWheel, trackSpinClick, trackSpinComplete };