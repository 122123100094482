/*
Herein is a hodge-podge of modal madness.  Migration is in progress.
The new approach is to consume ScModal from the component library.
*/

import modalQueue from "./modal-queue";
import onsiteMessageModalInit from "./onsite-messaging";
import { autoInstantWinWheelInit, instantWinWheelInit, chooseGameModule } from "./instant-win-wheel";
import { trackSpinWheel } from "./iw-ga";
import { createApp, ref, computed, onMounted } from "vue";
import { ScModal, ScCreatePassword } from "@pch/surffers-components";

import { POST_CONFIG, tmplHelper } from "../../common/helpers";



function observeCp() {
    return new Promise((resolve, reject) => {
        if(document.body.dataset.cpDone) resolve();

        const cpObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if(mutation.type === 'attributes' && mutation.attributeName === 'data-cp-done') { 
                    resolve();
                }
            });
        });
        cpObserver.observe(document.body, { attributes: true });
    });
}

// Hack until badge anims are sc-modals
function observeBadges() {

    return new Promise((resolve, reject) => {
        if(document.body.dataset.badgesDone) resolve();

        const badgeObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if(mutation.type === 'attributes' && mutation.attributeName === 'data-badges-done') {
                    resolve();
                }
            });
        });
        badgeObserver.observe(document.body, { attributes: true });
    });
}

function observeIWGame() {
    return new Promise((resolve, reject) => {
        if(document.body.dataset.iwDone) resolve();

        const iwObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if(mutation.type === 'attributes' && mutation.attributeName === 'data-iw-done') {
                    resolve();
                }
            });
        });
        iwObserver.observe(document.body, { attributes: true });
    });
}


const onsiteMessageModals = [...document.querySelectorAll('.onsite-messaging')];
onsiteMessageModals.forEach(modal => { 
    if(!modal.hasAttribute('deferred')) {
        onsiteMessageModalInit(modal)
        modalQueue.enqueue(modal)
    }
});

const autoInstantWinWheelModal = document.getElementById('auto-instant-win-wheel');
if(autoInstantWinWheelModal) {
    observeCp().then(() => { // Hack until badge anims are dialogs

        const gameModule = chooseGameModule();

        if(gameModule?.game_info?.auto_popup == true) {
            autoInstantWinWheelInit(gameModule);
            modalQueue.enqueue(autoInstantWinWheelModal);
            trackSpinWheel();
        } else {
            document.body.dataset.iwDone = true; 
        }
        
    });
} else {
    document.body.dataset.iwDone = true;
}

const instantWinWheelModal = document.getElementById('instant-win-wheel');
if(instantWinWheelModal) {
    instantWinWheelInit(window.PCH?.surveys?.bonusGameModule);
    const instantWinToggle = document.querySelector('[popovertarget="instant-win-wheel"]');
    if(instantWinToggle) {
        instantWinToggle.addEventListener('click', () => {
            modalQueue.enqueue(instantWinWheelModal);
            trackSpinWheel();
        });
    }
}

observeIWGame().then(() => {
    PCH.uninav.controller.autoUpdate();
});

const createPassApp = createApp({

    setup() {

        const registrationStep = ref('create');
        const showCpLb = ref(false);
        const rfError = ref(false);
        const rfMessage = ref('');
        
        const tmplData = {
            firstName: PCHUSER.firstName,
            tokens: parseInt(PCH?.surveys?.noPassData?.noPassBadgeTokens || 0),
            regTokens: parseInt(PCHUSER?.createPasswordTokens || 0),
            level: (PCHUSER?.createPasswordMemberStatus || 'bronze').toUpperCase()
        };

        const createPassTxt = PCH?.modals?.createPassText;
        const createStateTxt = createPassTxt?.create;
        const completeStateTxt = createPassTxt?.complete;
        const incompleteStateTxt = createPassTxt?.incomplete;

        const cpMainGreeting = computed(() => {
            switch (registrationStep.value) {
                case 'create':
                    return tmplHelper(tmplData, createStateTxt?.topLine) 
                case 'complete':
                    return tmplHelper(tmplData, completeStateTxt?.midLine1) 
                case 'incomplete':
                    return tmplHelper(tmplData, incompleteStateTxt?.topLine) 
                default:
                    break;
            }
        });

        const cpMessage = computed(() => {
            switch (registrationStep.value) {
                case 'create':
                    return tmplHelper(tmplData, createStateTxt?.midLine1) 
                case 'complete':
                    return tmplHelper(tmplData, completeStateTxt?.topLine) 
                case 'incomplete':
                    return tmplHelper(tmplData, incompleteStateTxt?.midLine1) 
                default:
                    break;
            }

        });

        const cpDirections = computed(() => {
            switch (registrationStep.value) {
                case 'create':
                    return tmplHelper(tmplData, createStateTxt?.midLine2) 
                case 'complete':
                    return tmplHelper(tmplData, completeStateTxt?.bottomLine) 
                case 'incomplete':
                    return tmplHelper(tmplData, incompleteStateTxt?.midLine2) 
                default:
                    break;
            }

        });

        const cpButtonInfo = computed(() => {
            switch (registrationStep.value) {
                case 'create':
                    return tmplHelper(tmplData, createStateTxt?.bottomLine) 
                case 'complete':
                    return tmplHelper(tmplData, completeStateTxt?.bottomLine) 
                case 'incomplete':
                    return tmplHelper(tmplData, incompleteStateTxt?.bottomLine) 
                default:
                    break;
            }

        });

        function cpClose(reload) {
            if(reload) {
                window.location.reload();
            } else {
                showCpLb.value = false;
                document.body.dataset.cpDone = true;
            }
        }

        const payload = {
            Password: null,
            ConfirmPassword: null,
            noPassBadgeTokensCargo: PCH?.surveys?.noPassData?.noPassBadgeTokensCargo,
        };

        async function createPassword(payload) {
            if(PCH?.surveys?.noPassData?.apiUri) {
                const response = await fetch(PCH?.surveys?.noPassData?.apiUri, { ...POST_CONFIG, body: JSON.stringify(payload)})
                    .then(response => response.ok ? response.json() : Promise.reject(response))
                    .then(json => {
                        if(json.ValidationResponses?.IsValid === false) {
                            return Promise.reject(json);
                        } else {
                            return true;
                        }
                    })
                    .catch(err => {
                        console.warn('create pass error:', err);
                        if(err.ValidationResponses.IsValid === false) {
                            rfError.value = true;
                            rfMessage.value = err?.ValidationResponses?.FieldOrDatabaseValidationResponse?.Responses[0]?.Message;
                        } else {
                            console.error('unknown password response');
                        }
                    });
                return response;
            } else {
                console.warn('no api for create password');
            }
        }

        async function cpSubmit() {
            if(registrationStep.value === 'create') {
                const apiSuccess = await createPassword(payload);
                if(apiSuccess) {    
                    registrationStep.value = 'complete';
                } 
            }
        }

        function cpYes() {
            if(registrationStep.value === 'complete') {
                cpClose();
            } else if(registrationStep.value === 'incomplete') {
                registrationStep.value = 'create';
            }
    
        }
    
        function cpNo() {
            if(registrationStep.value === 'complete') {
                cpClose();
            } else if(registrationStep.value === 'incomplete') {
                cpClose(true);
            }
        }

        function cpUpdatePayload(field, value) {
            payload[field] = value;
        }

        function cpNoThanks() {
            if(registrationStep.value = 'create') {
                registrationStep.value = 'incomplete';
            }
        }

        onMounted(() => {
            observeBadges().then(() => {
                if(PCHUSER.type === 1 && PCH?.surveys?.noPassData?.noPassBadgeTokens) {
                    showCpLb.value = true;
                } else {
                    document.body.dataset.cpDone = true;
                }
            });
        });

        return {
            registrationStep,
            showCpLb,
            cpSubmit,
            cpYes,
            cpNo,
            cpUpdatePayload,
            cpNoThanks,
            cpClose,
            rfError,
            rfMessage,
            cpMainGreeting,
            cpMessage,
            cpDirections,
            cpButtonInfo
        }
    }
});

createPassApp.component('sc-modal', ScModal);
createPassApp.component('sc-create-password', ScCreatePassword);
window.cpApp = createPassApp.mount('#createPassApp');

modalQueue.start();