<template>

    <div :style="{width: size + 'px', height: mobile ? size / 2 + 50 + 'px' : size + 'px', overflow: mobile ? 'hidden' : '', 
        marginBottom: '4rem'}"> 
        <!-- <div class="triangle" ref="marker" id="marker"> -->
            <svg class="triangle" ref="marker" id="marker" width="36" height="62" viewBox="0 0 42 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0153 21.115C39.0153 11.1696 30.953 3.1073 21.0077 3.1073C11.0623 3.1073 3 11.1696 3 21.115C3 24.0232 3.68941 26.7704 4.91372 29.2021C4.97088 30.0958 5.13887 31.002 5.4287 31.9056L14.3912 59.8478C16.455 66.2821 25.5588 66.2821 27.6226 59.8478L36.5851 31.9056C36.8745 31.0032 37.0425 30.0982 37.0998 29.2056C38.3252 26.7731 39.0153 24.0246 39.0153 21.115Z" fill="#212121"/>
                <path d="M4.91372 29.2021L7.18281 29.057L7.1532 28.594L6.94457 28.1796L4.91372 29.2021ZM5.4287 31.9056L7.59378 31.2112L7.59378 31.2112L5.4287 31.9056ZM14.3912 59.8478L12.2261 60.5423L14.3912 59.8478ZM27.6226 59.8478L29.7877 60.5423L27.6226 59.8478ZM36.5851 31.9056L34.42 31.2112L34.42 31.2112L36.5851 31.9056ZM37.0998 29.2056L35.0692 28.1827L34.8605 28.5969L34.8308 29.0598L37.0998 29.2056ZM21.0077 5.38103C29.6973 5.38103 36.7416 12.4254 36.7416 21.115L41.289 21.115C41.289 9.91387 32.2088 0.833579 21.0077 0.833579L21.0077 5.38103ZM5.27372 21.115C5.27372 12.4254 12.318 5.38103 21.0077 5.38103L21.0077 0.833579C9.80656 0.83358 0.726275 9.91387 0.726276 21.115L5.27372 21.115ZM6.94457 28.1796C5.87631 26.0579 5.27372 23.6601 5.27372 21.115L0.726276 21.115C0.726276 24.3863 1.50251 27.483 2.88288 30.2246L6.94457 28.1796ZM7.59378 31.2112C7.3612 30.4861 7.22805 29.7644 7.18281 29.057L2.64464 29.3472C2.7137 30.4272 2.91653 31.518 3.26362 32.6001L7.59378 31.2112ZM16.5562 59.1534L7.59378 31.2112L3.26362 32.6001L12.2261 60.5423L16.5562 59.1534ZM25.4576 59.1534C24.0692 63.482 17.9446 63.482 16.5562 59.1534L12.2261 60.5423C14.9653 69.0823 27.0485 69.0822 29.7877 60.5423L25.4576 59.1534ZM34.42 31.2112L25.4576 59.1534L29.7877 60.5423L38.7502 32.6001L34.42 31.2112ZM34.8308 29.0598C34.7854 29.7663 34.6523 30.487 34.42 31.2112L38.7502 32.6001C39.0968 31.5194 39.2996 30.4301 39.3689 29.3515L34.8308 29.0598ZM36.7416 21.115C36.7416 23.6613 36.1384 26.0602 35.0692 28.1827L39.1305 30.2286C40.5121 27.4859 41.289 24.3879 41.289 21.115L36.7416 21.115Z" fill="white"/>
                <ellipse cx="21.1369" cy="21.8173" rx="6.48455" ry="6.48454" transform="rotate(-90 21.1369 21.8173)" fill="white"/>
            </svg>
        <!-- </div> -->
    <div ref="wheel" class="circle" id="wheel" :style="{transform: 'rotate(' + offset + 'deg) translateZ(0)', width: size + 'px', height:  mobile ? 'calc(200% - 100px)' : '100%'}">
                <div v-for="i, index in prizes" class="sector" ref="sectors" :style="{transform: 'rotate(' + (index * (360 / sections)) + 'deg) skew(' + (90 - (360 / sections)) + 'deg)', overflow: 'hidden', height: size + 'px', width:size + 'px', marginTop:'-' + size + 'px', marginLeft: '-' + size + 'px'}">
                    <div ref="sector" class="sector__inner" :style="{transform: 'skew('+ (-90 + (360 / sections)) + 'deg) rotate(' + (360 - offset  ) + 'deg)', position: 'absolute', width: section_size + 'px', height: (size/2) + 'px', top:'63%', left:'63%'}">
                        <p class="sector__inner__text">{{ i.reward_title }}</p>
                    </div>
                </div>
        </div>
    </div>

</template>

<script setup>
    import { storeToRefs } from 'pinia';
    import { computed, onMounted, ref, watch } from "vue"
    import { useIwWheelStore } from './store/iwWheelStore.js';
        //datastore
        const store = useIwWheelStore();

        const { spinning, spinClicked } = storeToRefs(store)

        let currentWin = 0;

        //variables for initializing the wheel
        const mobile = store.mobile;
        const sections = store.gameInfo.wheel_config.length;
        const prizes = store.gameInfo.wheel_config;
        //probably doesn't have to be a var, might be useful for responsive design at some point
        const size = mobile ?  screen.width * .85 : 400;
        //this value will be passed in after the api call- might have to be computed to just get the positions of the wins on the wheel
        const ending = [1,2,3,4,5];
        //the size (width in px) of a single section based on the size given
        const section_size = computed(() => {
            let theta = 360 / sections;
            let theta_in_radians = theta * 3.141592653589793238462643383 / 180;

            return 2 * size / 2 * Math.sin(theta_in_radians / 2);
        });
        //the initial tilt of the wheel so it lays on the 1st section
        const offset = computed(() => {
            return 90 - (180 / sections)
        });

        // basically document.querySelector(ref)
        const wheel = ref('wheel');
        const marker = ref('marker');

        //vars for keeping track of wheel rotation
        let wheelRotation = offset.value;
        let happened = false;
        let end = 0;
        let prev = 0;
        let target = 0;

        //spins the wheel when store.spinning changes to true
        watch(spinning, (newValue) => {
            if (newValue) {
                if(store.spinResult[currentWin]){
                    target = prizes.map(function (o) { return o.key; }).indexOf(store.spinResult[currentWin].key);
                    end = 2 * sections - target + prev;
                    prev = target;
                    currentWin += 1;
                    //time the spin and marker animations to line up properly
                    marker.value.style = `animation-name: bounce, bounce; animation-duration: ${1 / (sections * 3)}s, ${2 / end}s; animation-timing-function: cubic-bezier(0, 1, 1, 1), cubic-bezier(0, 1, 1, 1); animation-iteration-count: ${(sections * 3)}, ${end - 2}; animation-delay: 0ms, 1000ms;`
                    //spin the wheel a few extra sections to end on the proper spot-- runs after the below
                    wheel.value.addEventListener('transitionend', function extraRotation() {
                        wheel.value.style = `transition: transform 2s ease-out; transform: rotate(${wheelRotation += (end * (360 / sections))}deg) translateZ(0); height:${size}px; width:${size}px;`
                        wheel.value.removeEventListener('transitionend', extraRotation)
                        if(target != -1) {
                            setTimeout(() => {
                                wheel.value.children[target].children[0].classList.add('green')
                                wheel.value.children[target].classList.add('green')
                                store.toggleSpinning();
                                setTimeout(() => {
                                    wheel.value.children[target].children[0].classList.remove('green')
                                    wheel.value.children[target].classList.remove('green')
                                    
                                }, 2000)
                            }, 2000) 
                        } else {
                            store.toggleSpinning();
                        }
                    })
                    //spin the wheel a couple of times to start-- runs before the above
                    wheel.value.style = `transition: transform 1s linear; transform: rotate(${wheelRotation += 1080}deg) translateZ(0); height:${size}px; width:${size}px;`
                } else {
                    store.complete();
                }
            }
        });

        watch(spinClicked, (newValue) => {
            if(newValue) {
                wheel.value.style = `transition: transform 10s linear; transform: rotate(${wheelRotation += 1080*10}deg) translateZ(0); height:${size}px; width:${size}px;`
            }
        });

        onMounted(() => {
            
            //removes animation from triangle after wheel stops spinning
            marker.value.addEventListener("animationend", function () {
                if (happened === true) {
                    marker.value.style = ""
                    happened = false
                } else {
                    happened = true
                }
            });

        });
</script>

<style lang="scss">
.circle {
    border: .556875rem solid white;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: transform;
    /* -webkit-mask-image: radial-gradient(circle, black 100%, rgba(0, 0, 0, 0) 100%); */
}

.sector {
    position: absolute;
    transform-origin: 100% 100%;
    top: 50%;
    left: 50%;
}

.sector:nth-child(odd) {
    background-color: var(--orange);
}

.sector:nth-child(even) {
    background-color: var(--cta-blue);
}

.orange {
    background-color: var(--orange);
}

.green {
    background-color: #66CC66 !important;
}

.sector__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0;
}

.sector__inner__text {
    z-index: 1;
    color: white;
    font-size: 1.25rem;
    transform: rotateZ(270deg);
    max-width: 75%;
    margin: 0;
    text-align: center;
}

.triangle {
    position: relative;
    left: calc(50% - 1.3125rem);
    margin-top: -1rem;
    top: 1rem;
    z-index: 10;
    transform-origin: 1.3125rem 1.3125rem;
}

@media screen and (max-width: 760px) {
    .sector__inner__text{
        font-size: 1rem;
    }
}

@keyframes bounce {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(-45deg)
    }

    100% {
        transform: rotate(0deg)
    }
}

</style>
