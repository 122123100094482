import { defineStore } from "pinia";

import { POST_CONFIG } from "../../../common/helpers.js";

import { trackSpinComplete } from "../../modals/iw-ga.js"

// const BONUS_GAME_DATA = Object.assign({}, window.PCH?.surveys?.bonusGameModule || {})
// const SPIN_API = BONUS_GAME_DATA?.apis?.spins_url;

// const SPIN_DELAY = 2000;

export const useIwWheelStore = defineStore('iw-wheel', {
    state: () => ({
        gameInfo: {},
        spinsApi: '',
        spinResult: [],
        spinErrors: null,
        spinning: false,
        spinsCompleted: false,
        spinClicked: false,
        apiComplete: false,
        apiError: null,
        mobile: isMobile ? true : false,
        noPassword: null,
        
    }),
    actions: {
        async spin() {
            if(!this.spinClicked) this.spinClicked = true;
            if(this.spinsApi && this.spinResult.length === 0) {
                axios.post(this.spinsApi, {}, POST_CONFIG)
                    .then(response => response.data)
                    .then(data => data.data)
                    .then(data => {
                        if(data?.wins) this.spinResult.push(...data?.wins);
                        this.spinResult = this.spinResult.map((win) => ({...win, ...this.gameInfo.wheel_config.find(config => config.key === win.key)}))
                        if (data?.noPassword) {
                            this.noPassword = data.noPassword;
                        }
                        if(data?.error) {
                            this.spinErrors = data?.error;
                            this.spinResult.push(...data?.error);
                        }
                        this.toggleSpinning();
                        this.apiComplete = true;
                    })
                    .catch(error => {
                        console.error(error);
                        this.apiError = error;
                        this.complete();
                        this.apiComplete = true;
                    });
            } else {
                console.warn('this.spinsApi is not defined');
            }
        },
        toggleSpinning(){
            this.spinning = !this.spinning;
        },
        complete() {
            this.spinsCompleted = true;
            trackSpinComplete();
        }
    }
});