import { clickOutsideCloses } from "./helpers";

/**
 * 
 * @param {HTMLDialogElement} dialog the dialog element
 */
export default function onsiteMessageModalInit(dialog) {

    const closeButton = dialog.querySelector('#onsite-messaging_close');

    const ctaButton = dialog.querySelector('button.onsite-messaging__button--cta'); //TODO: accommodate multiple CTAs

    const extraCloseButton = dialog.querySelector('[data-close]');

    closeButton.addEventListener('click', () => dialog.close());

    ctaButton.addEventListener('click', ()=> {
        PCHGA.trackEvent(`surveytab`, 'takeanothersurvey', 'invalidmid');
    })

    if(extraCloseButton) extraCloseButton.addEventListener('click', () => dialog.close());

    clickOutsideCloses(dialog);

};