<template>
    <button class="iwgame__spinbtn iwgame__spinbtn--pulse" v-if="!store.spinClicked && !store.apiComplete && !store.spinning" @click="spinClick">Spin</button>
    <button class="iwgame__spinbtn iwgame__spinbtn--pulse" v-if="store.spinClicked && !store.apiComplete && !store.spinning" @click="spinClick">Spin</button>
    <button class="iwgame__spinbtn iwgame__spinbtn--spinning" v-if="store.spinClicked && store.apiComplete && store.spinning" aria-hidden>Spin {{ spinNum }}...</button>
    <button class="iwgame__spinbtn iwgame__spinbtn--pulse" v-if="store.spinClicked && store.apiComplete && !store.spinning" @click="spinClick" aria-hidden><img src="./images/spin_check.svg" /></button>
</template>

<script setup>
import { watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useIwWheelStore } from './store/iwWheelStore.js';

const store = useIwWheelStore();
const { spinning } = storeToRefs(store)

let spinNum = ref(0);

const spinClick = () => {
    store.spin();
}

watch(spinning, (newValue, oldValue) => {
    if(newValue) {
        if(spinNum.value  < store.spinResult.length) {
            spinNum.value++;
        }
    }
});
</script>

<style scoped>
.iwgame__spinbtn {
    position: absolute;
    top: calc(50% - 0.46875rem);
    left: calc(50% - 1.875rem);

    width: 3.75rem;
    height: 3.75rem;

    border-radius: 50%;
    
    text-transform: uppercase;

    border: none;

    padding: 0;

    font-size: 1rem;
}
@media screen and (max-width: 48rem){
    .iwgame__spinbtn{
        top: calc(50% + 3.28125rem);
        left: calc(50% - 1.875rem);
    }
}

.iwgame__spinbtn--pulse {
    animation-name: scaleSpinBtn;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    font-weight: bold;
    font-size: 1.25rem;
    color: white;
    background-color: #66CC66;
}

.iwgame__spinbtn--rotate {
    animation-name: rotation;
    animation-duration: 0.25s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    font-weight: bold;
    font-size: 1.25rem;
    color: white;
    background-color: #66CC66;
}

.iwgame__spinbtn--spinning {
    font-size: 0.75rem;
    color: #66CC66;
    background-color: white;
}

@keyframes scaleSpinBtn {
    0% {
        transform: scale(1);
        background-color: #66CC66;
    }

    40% {
        transform: scale(1);
        background-color: #66CC66;
    }

    60% {
        transform: scale(1.2);
        background-color: #22B022;
    }

    100% {
        transform: scale(1.2);
        background-color: #22B022;
    }
}

@keyframes rotation{
  from{
    transform:rotate(0deg);
  }

  to{
    transform:rotate(-360deg);
  }
}


</style>