
import { createApp } from "vue";
import { createPinia } from "pinia";
import { useIwWheelStore } from "../vue-components/store/iwWheelStore";

import IwWheelGame from "../vue-components/IwWheelGame.vue";

const autoInstantWinWheelId = '#auto-instant-win-wheel';
const instantWinWheelId = '#instant-win-wheel';
const emailGameModule = window.PCH?.surveys?.emailIWGameConfig; 
const bonusGameModule = window.PCH?.surveys?.bonusGameModule;

export function chooseGameModule() {
    if(emailGameModule?.game_info) {
        return emailGameModule;
    } else if(bonusGameModule?.game_info) {
        return bonusGameModule;
    }

    return null;
}

export function autoInstantWinWheelInit(gameModule) {

    if(gameModule?.game_info?.wheel_config) {
        const pinia = createPinia();
        const iwWheelApp = createApp({
            setup() {
                const iwWheelStore = useIwWheelStore();
                iwWheelStore.gameInfo = gameModule?.game_info;
                iwWheelStore.spinsApi = gameModule?.apis?.spins_url;
            }
        });

        iwWheelApp.component('iw-wheel-game', IwWheelGame);
        
        iwWheelApp.use(pinia);
        iwWheelApp.mount(autoInstantWinWheelId);
    }

    return gameModule;
    
}

export function instantWinWheelInit(gameModule) {

    if(gameModule?.game_info?.wheel_config) {
        const pinia = createPinia();
        const iwWheelApp = createApp({
            setup() {
                const iwWheelStore = useIwWheelStore();
                iwWheelStore.gameInfo = gameModule?.game_info;
                iwWheelStore.spinsApi = gameModule?.apis?.spins_url;
            }
        });

        iwWheelApp.component('iw-wheel-game', IwWheelGame);
        
        iwWheelApp.use(pinia);
        iwWheelApp.mount(instantWinWheelId);
    }

    return gameModule;
    
}


