
/**
 * 
 * @param {HTMLDialogElement} dialog The dialog that should close when clicked outside of
 */
export function clickOutsideCloses(dialog) {
    dialog.addEventListener('click', event => {
        const rect = dialog.getBoundingClientRect();
        const isInDialog=(
            rect.top <= event.clientY 
            && event.clientY <= rect.top + rect.height
            && rect.left <= event.clientX 
            && event.clientX <= rect.left + rect.width
        );
            
        if (!isInDialog) {
            dialog.close();
        }
    });
}