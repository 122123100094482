class ModalQueue {

    #firstDialog;
    #lastDialog;

    constructor() {
        this.#firstDialog = null;
        this.#lastDialog = null;
    }

    #observe(dialog) {
        const observer = new MutationObserver(this.update.bind(this, dialog));
        observer.observe(dialog, { attributes: true, childList: false, subtree: false });
        dialog.observedBy = observer;
    }

    enqueue(dialog) {
        this.#observe(dialog);

        if(!this.#firstDialog) {
            this.#firstDialog = dialog;
            this.#lastDialog = dialog;
            this.start();
        } else {
            this.#lastDialog.nextDialog = dialog;
            this.#lastDialog = dialog;
        }
    }

    start() {
        if(this.#firstDialog) {
            this.#firstDialog.showModal();
        }
    }

    next() {
        if(this.#firstDialog?.nextDialog) {
            this.#firstDialog = this.#firstDialog.nextDialog;
            this.#firstDialog.showModal();
        } else {
            //reset
            this.#firstDialog = null;
            this.#lastDialog = null;
        }
    }

    update(dialog) {
        if(!dialog.hasAttribute('open')) {
            console.info('Open Next Modal!');
            this.#firstDialog.close();
            this.#firstDialog.observedBy.disconnect();
            this.next();
            
        } else {
            console.info("Don't change yet");
        }
    }

    
}

// const dialogs = [...document.getElementsByTagName('dialog')];

// console.info('MODAL:  dialogs......', dialogs);

const modalQueue = new ModalQueue();

export default modalQueue;