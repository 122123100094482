<template>
    <section class="iw-rewards">
        <header>
            <h3>Your Rewards:</h3>
        </header>
        <div class="iw-rewards__win highlight" v-for="win in wins" :key="win.key" v-green-light>
            <img aria-hidden src="./images/earned_check.svg" alt="" srcset="">
            <span v-html="win.text"></span>
        </div>
        
    </section>
</template>

<script setup>
    import { watch, ref } from 'vue';
    import { storeToRefs } from 'pinia';
    import { useIwWheelStore } from './store/iwWheelStore.js';

    const store = useIwWheelStore();
    const { spinning } = storeToRefs(store);

    const wins = ref([]);
    let currentWin = 0;

    function describe(win) {
        let text = win.description;
        if(win.winner_token) text = `${text}<span>${win.winner_token}</span>`;
        win.text = text;
    }    

    watch(spinning, (newValue, oldValue) => {
        if(!newValue && currentWin < store.spinResult.length) {
            const win = store.spinResult[currentWin]
            describe(win);
            if(store.mobile) {
                wins.value.unshift(win);
            } else {
                wins.value.push(win);
            }
            currentWin+=1;
            setTimeout(() => {
                store.toggleSpinning();
            }, 4000)
        }
    });

    const vGreenLight = {
        mounted(el) {
            if(!el.dataset.greenLit) {    
                el.classList.add('green-highlight');
                setTimeout(() => {
                    el.classList.remove('green-highlight');
                    el.dataset.greenLit = true;
                }, 1000);
            }
        },
    }
</script>

<style type="scss" scoped>
.iw-rewards {
    border: solid thin white;
    border-radius: 0.5rem;
}
@media screen and (min-width: 48rem) {
    .iw-rewards {
        width: 100%;
        align-self: flex-start;
    }
}

.iw-rewards header {
    padding: 1rem;
}
.iw-rewards header h3 {
    margin-bottom: 0;
    font-size: 1.5rem;
}

.iw-rewards__win {
    display: flex;
    padding: 0.5rem 1rem;
    border-top: solid thin white;

    font-size: 0.875rem;
}
.iw-rewards__win > span :deep(a) {
    text-decoration: underline;
    font-size: .625rem;
    font-style: italic;
    color: white;
    display: block;
    position: relative;
}
.iw-rewards__win > span :deep(span) {
    font-size: .75rem;
    font-style: italic;
    color: white;
    display: block;
    position: relative;
}
.iw-rewards__win img{
    margin-right: 1rem;
}

.highlight {
    transition: 
        color 0.5s cubic-bezier(0,1,1,0),
        font-weight 0.5s cubic-bezier(0,1,1,0),
        font-size 0.5s cubic-bezier(0,1,1,0);
}
.green-highlight {
    color: #66CC66;
    font-weight: bold;
    font-size: 0.9375rem;
}
</style>