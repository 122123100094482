<template>
    <section class="modal__topbar">
        <a :href="store.gameInfo.rules_url" target="_blank">Official Rules</a> | <a :href="store.gameInfo.facts_url" target="_blank">Sweepstakes Facts</a> <img v-if="!store.spinClicked" @click="closeModal" class="modal__close" src="./images/modal_close.svg" alt="Skip this opportunity">
    </section>
    
    <section class="iwgame">
        <header class="iwgame__header">
            <img class="iwgame__title" src="./images/stack_rewards.svg" alt="Stack The Rewards">
            <div v-if="!store.spinClicked" class="iwgame__header-msg">{{ store.gameInfo.header_message1 }}</div>
            <div v-if="!store.spinClicked" class="iwgame__header-msg">{{ store.gameInfo.header_message2 }}</div>
            <div v-if="store.spinClicked && !store.spinsCompleted" class="iwgame__header-msg" style="color: #66CC66;">{{ store.gameInfo.progress_message }}</div>
            <div v-if="store.spinsCompleted" class="iwgame__header-msg" style="color: #66CC66;">{{ store.gameInfo.completed_header_message }}</div>

        </header>
        <section class="iwgame__activity">
            <div class="iwgame__continue" v-fadein:500.opacityOnly="spinsCompleted">
                <span class="iwgame__continue__text">{{ store.gameInfo?.completed_screen_message }}</span>
                <button class="iwgame__continue__btn" @click="finishGame">Continue</button>
                <div class="continue-animation">
                    <div id="continue-animation__stars"></div>
                </div>
            </div>
            <div class="iwgame__wheelbox" v-fadeout.opacityOnly="spinsCompleted"> 
                <Wheel/>
                <iw-wheel-spin/>
            </div>
            <div class="iwgame__results" v-slideleft="spinsCompleted">
                
                <div class="iwgame__hitit" v-fadeout="store.spinClicked" >
                    <img aria-hidden src="./images/green_arrows.svg" alt=""/>
                    <span v-html="store.gameInfo?.hit_spin_message"></span>
                </div>

                <iw-wheel-rewards v-fadein:500="store.apiComplete"></iw-wheel-rewards>
            </div>
        </section>
        <section v-if="!store.spinClicked" class="iwgame__disclaimer">
            Wheel is for illustrative purposes only & does not represent the actual odds of winning.
        </section>
    </section>
</template>

<script setup>

import { ref, watch, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import createPassword from "../../common/js/trigger-create-password";
import Wheel from './Wheel.vue';
import IwWheelRewards from './IwWheelRewards.vue';
import IwWheelSpin from './IwWheelSpin.vue';

import { useIwWheelStore } from './store/iwWheelStore.js';
import onsiteMessageModalInit from "../modals/onsite-messaging";

import { loadAnimation } from 'lottie-web/build/player/lottie_light.min.js'
import animationData from "./personal-achievements-badge/animationData.json";

import { trackSpinClick } from "../modals/iw-ga"

const props = defineProps(['error-dialog-id', 'dialog-id']);
const store = useIwWheelStore();

const { spinsCompleted, spinClicked, mobile } = storeToRefs(store)

const errorDialog = document.getElementById(props.errorDialogId);
const dialog = document.getElementById(props.dialogId);
if(errorDialog) onsiteMessageModalInit(errorDialog);

const closeModal = () => {
    dialog.close();
    document.body.dataset.iwDone = true;
};

const finishGame = () => { 
    if(store.noPassword) {
        closeModal();
        PCH.modals.createPassword.ready = () => {
            document.querySelector('.sso-lightbox .sso-forfeit').addEventListener('click', () => {
                window.scrollTo(0, 0);
                window.location.reload();
            });
        };
        createPassword({
            lightbox: 1,
            tokens: store.noPassword.noPassTokens,
            api: store.noPassword.apiUri,
            cargo: store.noPassword.noPassIWGameTokensCargo,
        });
    } else {
        window.scrollTo(0, 0);
        window.location.reload()
    }
    
};

const showErrorModal = () => {
    if(store.apiError) {
        errorDialog.querySelector('.onsite-messaging__text__blurb').innerHTML = `Sorry, an unexpected error occurred. Please refresh the page or click continue`;
        errorDialog.querySelector('[data-close]').addEventListener('click', () => window.location.reload())
        errorDialog.showModal();
    }
    else if(store.spinErrors) {
        let msg = `Looks like you missed out on ${store.spinErrors.length} spins. Sorry about that! ${
            store.spinErrors.length != store.spinResult.length ? 'But you did earn:' +
            store.spinResult.flatMap((result) => result.key && result.reward_title  ? ' ' + result.reward_title : []) + '. ' : ''}To try to make it up to you, we're giving you an extra ${
            Intl.NumberFormat().format(store.spinErrors.reduce((accum, current) => accum + parseInt(current.value), 0))} tokens!`
        errorDialog.querySelector('.onsite-messaging__text__blurb').innerHTML = msg;

        errorDialog.showModal(); 


    }
};

watch(spinsCompleted, (newValue) => {
    if(newValue){
        stars();
        if(store.spinErrors || store.apiError) {
            showErrorModal();
        }
    }
});

watch(spinClicked, (newValue) => {
    if(newValue){
        dialog.addEventListener('cancel', e => e.preventDefault());
        trackSpinClick()
    }
});

const stars = () => {
    return loadAnimation({
        animationData: animationData,
        container: document.getElementById("continue-animation__stars"), // Required
        path: "./personal-achievements-badge/animationData.json", // Required
        renderer: "svg", // Required
        loop: true, // Optional
        autoplay: true, // Optional
        name: "Badge Animation", // Optional
    });
}

// Directives
const vFadeout = {
    mounted(el) {
        el.classList.add('fade');
    },

    updated(el, binding) {
        const delay = parseInt(binding.arg || 1);
        if(binding.oldValue !== binding.value && binding.value) {
            setTimeout(() => {
                el.classList.add('fade-out');
                if(!binding.modifiers.opacityOnly || mobile.value) {
                    setTimeout(() => {
                        el.style.display = 'none';
                    }, 500);
                }
            }, delay);    
        }
    }
}

const vFadein = {
    mounted(el, binding) {
        el.classList.add('fade', 'fade-out');
        if(!binding.modifiers.opacityOnly || mobile.value) el.style.display = 'none';
    },

    updated(el, binding) {
        const delay = parseInt(binding.arg || 1);
        if(binding.oldValue !== binding.value && binding.value) {
            setTimeout(() => {
                if(!binding.modifiers.opacityOnly || mobile.value) el.style.display = '';
                setTimeout(() => {
                    el.classList.remove('fade-out');
                }, 1);
            }, delay);
        }
    }
}

const vSlideleft = {
    mounted(el) {
        el.classList.add('slide');
    },

    updated(el, binding) {
        const delay = parseInt(binding.arg || 1);
        if(binding.oldValue !== binding.value && binding.value) {
            setTimeout(() => {
                el.classList.add('slide-left');
            }, delay);
        }
    }
}
</script>

<style scoped>

.modal__topbar {
    position: relative;
    margin: 0.5rem 0 2rem;
    text-align: center;
    color: #BABABA;
}
@supports (-webkit-hyphens:none){
    .modal__topbar {
        margin: 3rem 0 2rem;
    }
}
@media screen and (min-width: 48rem) {
    .modal__topbar {
        text-align: right;
        margin: 0.5rem 3rem 2rem;
        padding-right: 3rem;
    }
}

.modal__topbar a {
    color: #BABABA;
    text-decoration: none;
}

.modal__topbar .modal__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

/*
iwgame wrapper styles
*/
.iwgame {
    width: 95%;
    max-width: 51rem;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1.4;
    letter-spacing: 0.03em;
}

/*
iwgame header styles
*/
.iwgame__header {
    position: relative;
    text-align: center;
    font-size: 1.25rem;
    
}
.iwgame__header .iwgame__title {
    width: 100%;
    max-width: 48.75rem;
    margin-bottom: 1.5rem;
}
@media screen and (min-width: 48rem) {
    .iwgame__header {
        min-height: 10.75rem;
    }
    .iwgame__header .iwgame__title {
        margin-bottom: 0.8125rem;
    }
}



.iwgame__header-msg {
    font-size: 1.25rem;
    padding-bottom: 1.5rem;
}
@media screen and (min-width: 48rem) {
    .iwgame__header-msg {
        padding-bottom: 0;
    }
}

/*
All game activity is contained in this section.  Spinning and rewarding.
*/
.iwgame__activity {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media screen and (min-width: 48rem) {
    .iwgame__activity {
        position: relative;
        flex-direction: row;
        justify-content: space-around;
    }
}


/*
The wrapper around the Wheel component. Also contains the spin button
*/
.iwgame__wheelbox {
    position: relative;
    width: 25rem;
}
@media screen and (max-width: 48rem) {
    .iwgame__wheelbox {
        width: 100%;
        display: flex;
        justify-content: center;
        order: 1;
    }
}

/*
Area that will display the results of the spin
*/
.iwgame__results {
    position: relative;
    width: 100%;
    order: 2;
}

@media screen and (min-width: 48rem) {
    .iwgame__results {
        position: absolute;
        left: 60%;
        top: 1.5rem;
        width: 21.125rem;
        height: 100%;
        display: flex;
    }
}




/*
The message that appears when the user has not yet clicked the spin button: `hit_spin_message`
*/
.iwgame__hitit {
    

    display: flex;
    align-items: center;
    flex-direction: column;
    
    
    color: #66CC66;
    font-size: 1.75rem;
    text-align: center;
}
.iwgame__hitit img {
    animation: arrow 1s infinite;
}
@keyframes arrowDesktop {
    0% {
        transform: translateX(1rem) rotate(-90deg);
    }
    50% {
        transform: translateX(.5rem) rotate(-90deg);
    }
    100% {
        transform: translateX(1rem) rotate(-90deg);
    }
}
@keyframes arrow {
    0% {
        transform: translateY(-1rem);
    }
    50% {
        transform: translateY(-.5rem);
    }
    100% {
        transform: translateY(-1rem);
    }
}
@media screen and (min-width: 48rem) {
    .iwgame__hitit {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        left: 1.5rem;
        top: 1.5rem;
    }
    .iwgame__hitit img {
        animation: arrowDesktop 1s infinite;
        left: -3rem;
        position: absolute;
        width: 4.375rem;
        height: 3.375rem;
    }
}

.iwgame__continue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem;
}
@media screen and (min-width: 48rem) {
    .iwgame__continue {
        width: 20rem;
        order: 3;
    }
}

.iwgame__continue__text {
    text-align: center;
}

.iwgame__continue__header {
    text-align: center;
}

.iwgame__continue__btn {
    margin-top: 2rem;
    width: 13rem;
    height: 2.5rem;
    color: white;
    cursor: pointer;
    border-radius: 2rem;
    font-size: 1.25rem;
    border: none;

    animation: color-pulse 3s linear infinite alternate;
}
.iwgame__continue__btn:hover {
    animation: none;
    color: #22B022;
    background-color: white;
}

.continue-animation {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
#continue-animation__stars {
    height: 20rem;
    width: 20rem;
    position: absolute;
    top: -2rem;
    z-index: -1;
    left: -9rem;
}
/*
Some general purpose utility classes
*/
.fade {
    transition: opacity 0.5s ease-in-out;
}
.fade.fade-out {
    opacity: 0;
}

@media screen and (min-width: 48rem) {
    .slide {
        transition: left 0.5s ease-in-out;
    }

    .slide.slide-left {
        left: 0;
    }

}

@keyframes color-pulse {
    0% {
        background-color: #66CC66;
    }

    40% {
        background-color: #66CC66;
    }

    60% {
        background-color: #22B022;
    }

    100% {
        background-color: #22B022;
    }
}

.iwgame__disclaimer {
    font-size: 0.75rem;
    color: #BABABA;
    text-align: center;
}
@media screen and (min-width: 48rem) {
    .iwgame__disclaimer {
        width: 25rem;
    }
}
</style>

